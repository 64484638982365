import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import data from "./main/dress";
import ShowHeader from "./components/ShowHeader";
import Loading from "./components/Loading";
function App() {
  const HomePage = lazy(() => import('./components/HomePage'));
  const Productpage = lazy(() => import('./components/Productpage'));
  const AddAddresspage = lazy(() => import('./components/AddAddresspage'));
  const CheckOutpage = lazy(() => import('./components/CheckOutpage'));
  const PaymentPage = lazy(() => import('./components/PaymentPage'));
  const Cartpage = lazy(() => import('./components/Cartpage'));
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent F12 key (Developer Tools)
      if (e.keyCode === 123) {
        e.preventDefault();
      }
      // Prevent Ctrl+Shift+I (Developer Tools)
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
        e.preventDefault();
      }
      // Prevent Ctrl+Shift+J (Developer Tools)
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
        e.preventDefault();
      }
      // Prevent Ctrl+U (View Source)
      if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
        e.preventDefault();
      }
      // Prevent Ctrl+Shift+C (Inspect Element)
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('contextmenu', (err) => { err?.preventDefault() })
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('contextmenu', (err) => { err?.preventDefault() })
    }
  }, [])
  useEffect(() => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i)
    ) {
      console.log("ios")                 
    } else {
      function isInstagramBrowser() {
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        return (ua.indexOf('Instagram') > -1) || (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
      }
      
      function redirectToChrome() {
        var androidUrl = "intent://telicommoresale.shop/#Intent;scheme=https;package=com.android.chrome;end;";
        var fallbackUrl = "https://telicommoresale.shop/";

        if (/android/i.test(navigator.userAgent)) {
          window.location.href = androidUrl;
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          alert('To complete your payment, please open this link in Safari or Chrome.');
        } else {
          window.location.href = fallbackUrl;
        }
      }

      if (isInstagramBrowser()) {
        redirectToChrome();
      }
    }

  }, [])
  return (
    <>
      <Routes>
        <Route path="/" element={<Suspense fallback={<Loading />}><ShowHeader /></Suspense>}>
          <Route path="/" element={<Suspense fallback={<Loading />}><HomePage data={data} /></Suspense>} />
          <Route path="/productdetails/:id/:name" element={<Suspense fallback={<Loading />}><Productpage data={data} /></Suspense>} />
          <Route path="/addaddress" element={<Suspense fallback={<Loading />}><AddAddresspage /></Suspense>} />
        </Route>
        <Route path="/cart" element={<Suspense fallback={<Loading />}><Cartpage data={data} /></Suspense>} />
        <Route path="/checkout" element={<Suspense fallback={<Loading />}><CheckOutpage data={data} /></Suspense>} />
        <Route path="/payment" element={<Suspense fallback={<Loading />}><PaymentPage data={data} /></Suspense>} />
      </Routes>
      <ToastContainer
        className={"!bottom-[80px]"}
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
